<template>
  <div class="page">
    <Breadcrumbs :margin="24"/>
    <OrganizationsHeadSection/>
    <FilterTabs :items="tabs" v-model="selectedTab" class="tabs-left" style="margin-top: 20px;"/>
    <template v-if="selectedTab===1">
      <OrganizationsFilters :filters="filters" />
      <div class="row" >
        <div class="col-100">
          <Map
              :center="centerCity"
              :markers="organizationsMarkers" style="width: 100%;"/>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-100 organizations__block">
          <OrganizationCard
              v-for="organization in organizations" :key="organization.id"
              :organization="organization"/>
        </div>
        <div class="col-100 mt-4" style="display:flex; justify-content: center">
          <div slot="spinner" v-if="isLoading">
            <div class="is-loading">
              <LoadingRing/>
              <span>
              Загрузка...
            </span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="selectedTab===2">
      <CardsFilter/>
    </template>
  </div>
</template>

<script>
import LoadingRing from '@/components/LoadingRing';
import FilterTabs from '@/components/FilterTabs';
import Map from '@/components/Map';

export default {
  name: 'MainViewOrganizations',

  components: {
    Map,
    FilterTabs,
    LoadingRing,
  },

  data() {
    return {
      filters: {
        q: null,
        child_age: null,
        city: null,
        type: this.$route.params.type?.map(item => item.id),
      },
      pagination: {
        limit: 20,
        page: 1,
      },
      organizations: [],
      organizationsMaps: [],
      isLoading: false,
      tabs: [
        {
          name: 'Образовательные организации',
          id: 1,
        },
        {
          name: 'Образовательные программы',
          id: 2,
        },
      ],
      selectedTab: 1,
    };
  },
  computed: {
    cities: function() {
      return this.$cities;
    },
    organizationsMarkers() {
      return this.organizationsMaps.reduce((markers, organization) => {
        if (organization.contacts) {
          // eslint-disable-next-line no-prototype-builtins
          const organizationContacts = organization.contacts.filter(contact => contact.hasOwnProperty('coords')) || [];
          const organizationMarkers = organizationContacts.map(contact => {
            return {
              coords: contact.coords || [37.626543, 55.753823],
              title: contact.name,
              subtitle: organization.short_name,
              description: contact.address,
            };
          });
          if(organizationMarkers.length) {
            return markers.concat(organizationMarkers)
          }
        }
        // return markers.concat(organizationMarkers);
        return markers;
      }, []);
    },
    centerCity () {
      if (this.filters.city) {
        const city = this.cities.data.find(el => el.id === this.filters.city)
        return city.coords
      }
      return []
    }
  },
  methods: {
    getOrganizations() {
      this.isLoading = true
      this.$http.get(`${process.env.VUE_APP_DEFAULT_DEVELOP_HOST}api/institutions`, {
        params: {
          ...this.filters,
          ...this.pagination,
        },
      }).then(({data}) => {
        if (data.data.length) {
          this.organizations.push(...data.data);
        }
        this.isLoading = false
      });
    },
    // @TODO получение всех организаций
    getOrganizationsMap() {
      this.isMapLoaded = false;
      this.$http.get(`${process.env.VUE_APP_DEFAULT_DEVELOP_HOST}api/institutions`, {
        params: {
          ...this.filters,
        },
      }).then(res => {
        this.organizationsMaps = res.data.data;
      }).finally(() => {
        this.isMapLoaded = true;
      });
    },
    showNewList () {
      if (this.isLoading) { return }
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 150) {
        if (this.organizations.length > (this.pagination.page - 1) * this.pagination.limit) {
          this.pagination.page += 1;
          this.getOrganizations()
        }
      }
    }
  },
  watch: {
    filters: {
      deep: true,
      handler: function() {
        this.pagination.page = 1;
        this.organizations = [];
        this.getOrganizations()
      },
    },
  },
  mounted () {
    this.getOrganizations()
    this.getOrganizationsMap()
    document.addEventListener('scroll', this.showNewList)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.showNewList)
  },
};
</script>

<style lang="scss">
.organizations{
  &__block{
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(auto-fill, minmax(212px, 1fr));
  }
}
</style>
